import { Box, Stack, styled } from "@mui/material";
import { Button, ContextualSaveBar, EmptyState, Layout, LegacyCard, Page, Spinner } from "@shopify/polaris";
import * as yup from "yup";
import { ICustomerPortalTheme } from "@smartrr/shared/entities/CustomerPortalTheme";
import { isValidImageUrl } from "@smartrr/shared/utils/isValidImageUrl";
import { RequireNilFields } from "@smartrr/shared/utils/RequireNilFields";
import { FormikErrors } from "formik";
import { isEqual, isNil, merge, pickBy } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useTypedForm } from "@vendor-app/app/_sharedComponents/TypedForm/useTypedForm";
import { useActiveOrganizationSubSelector } from "@vendor-app/app/_state/reducers/organizations";

import { toggleSwitchButtons } from "./libs/constants";
import { useDefaultEmailConfig, useEmailPreviewHTML } from "./libs/hooks";
import { simulateClickOnEle } from "@smartrr/shared/utils/simulateActions";
import { ColorPicker } from "../../../AdminThemingRoute/components/ColorPicker";
import { ColorValues } from "../../../AdminThemingRoute/sections/styles";
import { ColorPickerContainer } from "../../../AdminThemingRoute/styles";
import {
  CustomerNotificationViews,
  ICustomerEmailPageProps,
  ICustomerEmailTheming,
} from "@smartrr/shared/entities/CustomerEmailConfig/types";
import { EmailPreview } from "./libs/components/EmailPreview";
import { EmailNotificationPreviewModal } from "@vendor-app/app/_sharedComponents/NotificationEmails/EmailPreviewModal";
import { useEmailPreviewModalStore } from "@vendor-app/app/_sharedComponents/NotificationEmails/EmailPreviewModal/lib/useEmailPreviewModalStore";
import { EmailNotificationAccessStore } from "@vendor-app/app/_state/zustand/EmailNotificationsStore";
import { NotificationEmails } from "@smartrr/shared/entities/CustomerEmailConfig/schemas";
import { useEmails } from "./libs/hooks/useEmails";
import { useEmailContext } from "./libs/hooks/useEmailContext";

const EmptyStateWrapper = styled("div")`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 272px;
  & .Polaris-Box {
    padding-bottom: 20px;
  }
`;

export const Color: React.FC<{
  value: string; // TODO: Add strict typing to prevent value misspellings
  title: string;
  valueName: string;
  setThemeFieldValue: (
    field: string,
    shouldValidate?: boolean | undefined | string
  ) =>
    | Promise<void>
    | Promise<FormikErrors<RequireNilFields<ICustomerPortalTheme>>>
    | Promise<FormikErrors<ICustomerEmailTheming>>;
}> = ({ value, title, valueName, setThemeFieldValue }) => {
  return (
    <ColorPickerContainer>
      <ColorPicker defaultColor={value} onChange={color => setThemeFieldValue(valueName, color)} />
      <ColorValues>
        <div>{title}</div>
        <div>{value}</div>
      </ColorValues>
    </ColorPickerContainer>
  );
};

export const EditTemplates = (props: ICustomerEmailPageProps): JSX.Element => {
  const { emailConfigs } = EmailNotificationAccessStore.useEmailConfigs();
  if (!emailConfigs) {
    return <React.Fragment />;
  }
  return <EditTemplatesInner {...props} emailConfigs={emailConfigs} />;
};

const EditTemplatesInner = ({
  setView,
  emailConfigs,
}: ICustomerEmailPageProps & { emailConfigs: NotificationEmails.Type }) => {
  const { addToast } = useToast();
  const { update: updateEmailNotification } = EmailNotificationAccessStore.useActions();

  const orgShopifyData = useActiveOrganizationSubSelector(state => state?.shopifyStoreData);
  const { emailsSortedBySection: emailPreviews } = useEmails({
    defaults: true,
    allowShopifyEmails: false,
    emailRecipient: "customer",
  });

  const defaultEmailConfig = useDefaultEmailConfig();

  const { closeModal, isModalOpen, openModal } = useEmailPreviewModalStore(state => state);
  const boxRef = useRef<HTMLDivElement>(null);

  const onSaveEmailTheming = useCallback(
    async (values: ICustomerEmailTheming) => {
      const isValidImg = await isValidImageUrl(values.logo);
      if (values.logo && !isValidImg && values.useHeaderAndFooter) {
        addToast("Please enter a valid image url", true);
        return;
      }
      updateEmailNotification({
        ...emailConfigs,
        emailTheming: merge({}, defaultEmailConfig.emailTheming, values),
      });
      addToast("Default template saved");
    },
    [emailConfigs, defaultEmailConfig]
  );

  const onDiscard = useCallback(
    (useStyling: boolean, useHeaderAndFooter: boolean) => {
      if (useStyling !== emailConfigs.emailTheming?.useStyling) {
        toggleUseStylingRadio();
      }
      if (useHeaderAndFooter !== emailConfigs.emailTheming?.useHeaderAndFooter) {
        toggleUseHeaderAndFooterRadio();
      }

      setEmailThemeValue("logo", emailConfigs.emailTheming?.logo || defaultEmailConfig.emailTheming.logo);
      setEmailThemeValue(
        "logoWidth",
        emailConfigs.emailTheming?.logoWidth || defaultEmailConfig.emailTheming.logoWidth
      );
      setEmailThemeValue(
        "colors.backgroundColor",
        emailConfigs.emailTheming?.colors.backgroundColor ||
          defaultEmailConfig.emailTheming.colors.backgroundColor
      );
      setEmailThemeValue(
        "colors.primaryTextColor",
        emailConfigs.emailTheming?.colors.primaryTextColor ||
          defaultEmailConfig.emailTheming.colors.primaryTextColor
      );
      setEmailThemeValue(
        "colors.secondaryTextColor",
        emailConfigs.emailTheming?.colors.secondaryTextColor ||
          defaultEmailConfig.emailTheming.colors.secondaryTextColor
      );
      setEmailThemeValue(
        "colors.buttonBackgroundColor",
        emailConfigs.emailTheming?.colors.buttonBackgroundColor ||
          defaultEmailConfig.emailTheming.colors.buttonBackgroundColor
      );
      setEmailThemeValue(
        "colors.buttonTextColor",
        emailConfigs.emailTheming?.colors.buttonTextColor ||
          defaultEmailConfig.emailTheming.colors.buttonTextColor
      );
    },
    [emailConfigs, defaultEmailConfig]
  );

  const onReset = useCallback(
    (useStyling: boolean, useHeaderAndFooter: boolean) => {
      if (useStyling !== defaultEmailConfig.emailTheming.useStyling) {
        toggleUseStylingRadio();
      }
      if (useHeaderAndFooter !== defaultEmailConfig.emailTheming.useHeaderAndFooter) {
        toggleUseHeaderAndFooterRadio();
      }

      setEmailThemeValue("logo", defaultEmailConfig.emailTheming.logo);
      setEmailThemeValue("logoWidth", defaultEmailConfig.emailTheming.logoWidth);
      setEmailThemeValue("colors.backgroundColor", defaultEmailConfig.emailTheming.colors.backgroundColor);
      setEmailThemeValue("colors.primaryTextColor", defaultEmailConfig.emailTheming.colors.primaryTextColor);
      setEmailThemeValue("colors.secondaryTextColor", defaultEmailConfig.emailTheming.colors.secondaryTextColor);
      setEmailThemeValue(
        "colors.buttonBackgroundColor",
        defaultEmailConfig.emailTheming.colors.buttonBackgroundColor
      );
      setEmailThemeValue("colors.buttonTextColor", defaultEmailConfig.emailTheming.colors.buttonTextColor);
      emailConfigs.emailHeader = defaultEmailConfig.emailHeader;
      emailConfigs.emailFooter = defaultEmailConfig.emailFooter;
    },
    [defaultEmailConfig, emailConfigs]
  );

  const {
    useField,
    useValues,
    setFieldValue: setEmailThemeValue,
  } = useTypedForm<ICustomerEmailTheming>({
    initialValues: {
      ...defaultEmailConfig.emailTheming,
      ...pickBy(emailConfigs.emailTheming, value => !isNil(value)),
    },
    validationSchema: yup.object(), // TODO: Deprecate yup validation in favor of zod
    validateOnChange: true,
    onSubmit: onSaveEmailTheming,
  });

  const formValues = useValues();

  const hasChanges = useMemo(
    () =>
      !isEqual(formValues, {
        ...defaultEmailConfig.emailTheming,
        ...pickBy(emailConfigs.emailTheming, value => !isNil(value)),
      }),
    [emailConfigs.emailTheming, defaultEmailConfig.emailTheming, formValues]
  );

  const { useHeaderAndFooter, useStyling, colors } = formValues;

  const { Input: LogoInput } = useField("logo");
  const { Input: LogoWidth } = useField("logoWidth");
  const { Radio: HeaderAndFooterToggle } = useField("useHeaderAndFooter");
  const { Radio: StylingToggle } = useField("useStyling");

  const context = useEmailContext(formValues);

  const [activeEmailPreview, setActiveEmailPreview] = useState({ ...emailPreviews.SUBSCRIPTION_CREATE_SIGNUP });

  const disableHeaderAndFooter = activeEmailPreview.disableHeaderAndFooter;

  const onPreview = useCallback(() => {
    if (isModalOpen) {
      closeModal();
      return;
    }

    openModal({
      emailPreview: activeEmailPreview,
      ableToSendPreview: true,
      defaults: true,
      formValues,
    });
  }, [emailPreviews, formValues, isModalOpen, activeEmailPreview]);

  /**
   * These functions are needed as changing the direct formik value doesn't update the actual component
   * ie: radio's value will be changed to `false` but component will still be in `true` position / state.
   * To combat this, I'm simulating a mouse click on the component instead.
   */
  const toggleUseStylingRadio = () => {
    simulateClickOnEle(boxRef, ".stylingToggleRadioWrapper input");
  };

  const toggleUseHeaderAndFooterRadio = () => {
    simulateClickOnEle(boxRef, ".headerFooterToggleRadioWrapper input");
  };

  const previewHTML = useEmailPreviewHTML({
    manipulatedEmailConfigData: activeEmailPreview,
    disableHeaderAndFooter,
    defaultsEnabled: true,
    currBody: activeEmailPreview.emailBody ?? "",
  });

  const paginationHandler = useCallback(
    (direction: number) => {
      const emailPreviewEntries = Object.entries(emailPreviews);
      const currIndex = emailPreviewEntries.findIndex(e => e[1].title === activeEmailPreview.title);
      const totalEntries = emailPreviewEntries.length;

      const newIndex = (currIndex + direction + totalEntries) % totalEntries;
      setActiveEmailPreview({ ...emailPreviewEntries[newIndex][1] });
    },
    [activeEmailPreview.title, emailPreviews]
  );

  /**
   * With the move to have logic within useGenerateEmailConfigOptions to be async, we need to account for
   * emailPreviews taking an additional render to fully populate with the expected emailBodies
   *
   * This useEffect ensures that activeEmailPreview has a emailBody after it has been initially set in setState above
   */
  useEffect(() => {
    if (!activeEmailPreview.emailBody && emailPreviews.SUBSCRIPTION_CREATE_SIGNUP.emailBody) {
      setActiveEmailPreview({ ...emailPreviews.SUBSCRIPTION_CREATE_SIGNUP });
    }
  }, [emailPreviews]);

  if (!orgShopifyData) {
    return <Spinner />;
  }
  return (
    <Box
      ref={boxRef}
      sx={{
        ".Polaris-Layout__Section:not(.Polaris-Layout__Section--secondary)": { width: "710px" },
        ".Polaris-FormLayout__HelpText": {
          maxWidth: "420px",
        },
      }}
    >
      {hasChanges ? (
        <ContextualSaveBar
          message="Unsaved changes"
          saveAction={{
            onAction: () => onSaveEmailTheming(formValues),
            content: "Save changes",
            disabled: false,
          }}
          discardAction={{
            content: "Discard changes",
            onAction: () => onDiscard(useStyling, useHeaderAndFooter),
          }}
        />
      ) : null}
      <Page
        backAction={{
          content: "Products",
          onAction: () => setView(prev => ({ ...prev, view: CustomerNotificationViews.DEFAULT })),
        }}
        title="Customize default templates"
        compactTitle
        fullWidth
      >
        <Layout>
          <div style={{ maxWidth: "292px" }}>
            <Layout.Section secondary>
              <LegacyCard title="Settings" sectioned>
                <Stack
                  spacing="20px"
                  maxWidth="232px"
                  sx={{
                    "[class*='sizeSlim']": {
                      width: "max-content",
                    },
                  }}
                >
                  <Stack direction="column" spacing="5px">
                    <Stack justifyContent="space-between" flexDirection="row" alignItems="center">
                      <p style={{ fontWeight: 500 }}>Enable default template</p>
                      <span className="stylingToggleRadioWrapper">
                        <StylingToggle name="radio" align="left" label="" buttons={toggleSwitchButtons} useMui />
                      </span>
                    </Stack>
                    <p style={{ color: "var(--p-color-text-subdued)" }}>
                      Disable if you prefer to use custom HTML for all email templates
                    </p>
                  </Stack>
                  <Stack direction="column" spacing="5px">
                    <Stack justifyContent="space-between" flexDirection="row" alignItems="center">
                      <p style={{ fontWeight: 500 }}>Enable email header / footer</p>
                      <span className="headerFooterToggleRadioWrapper">
                        <HeaderAndFooterToggle
                          name="radio"
                          align="left"
                          disabled={!useStyling}
                          label=""
                          buttons={toggleSwitchButtons}
                          useMui
                        />
                      </span>
                    </Stack>
                    <p style={{ color: "var(--p-color-text-subdued)" }}>
                      Injects a standard header and footer across all emails
                    </p>
                  </Stack>
                  <Button size="slim" onClick={() => onReset(useStyling, useHeaderAndFooter)}>
                    Reset default styles
                  </Button>
                </Stack>
              </LegacyCard>
              {useHeaderAndFooter && useStyling ? (
                <LegacyCard title="Logo" sectioned>
                  <Stack spacing="20px" direction="column">
                    <LogoInput
                      usePolaris
                      label="Media url"
                      placeholder={"https://www.website.com/image.jpg"}
                      type="text"
                      disabled={!useStyling || !useHeaderAndFooter}
                      autoComplete="off"
                    />

                    <Stack spacing="5px">
                      <LogoWidth
                        label="Logo width"
                        type="number"
                        min={1}
                        max={600}
                        disabled={!useStyling || !useHeaderAndFooter}
                        autoComplete="off"
                        usePolaris
                        suffix="pixels"
                      />
                    </Stack>
                  </Stack>
                </LegacyCard>
              ) : null}
              {useStyling ? (
                <LegacyCard title="Colors" sectioned>
                  <Box
                    sx={{
                      "[class*='color-picker-container']:last-child": {
                        paddingBottom: 0,
                      },
                    }}
                  >
                    <Color
                      title={"Background color"}
                      valueName={"colors.backgroundColor"}
                      value={colors.backgroundColor.toUpperCase()}
                      setThemeFieldValue={setEmailThemeValue}
                    />
                    <Color
                      title={"Primary text color"}
                      valueName={"colors.primaryTextColor"}
                      value={colors.primaryTextColor.toUpperCase()}
                      setThemeFieldValue={setEmailThemeValue}
                    />
                    <Color
                      title={"Secondary text color"}
                      valueName={"colors.secondaryTextColor"}
                      value={colors.secondaryTextColor.toUpperCase()}
                      setThemeFieldValue={setEmailThemeValue}
                    />
                    <Color
                      title={"Button color"}
                      valueName={"colors.buttonBackgroundColor"}
                      value={colors.buttonBackgroundColor.toUpperCase()}
                      setThemeFieldValue={setEmailThemeValue}
                    />
                    <Color
                      title={"Button text color"}
                      valueName={"colors.buttonTextColor"}
                      value={colors.buttonTextColor.toUpperCase()}
                      setThemeFieldValue={setEmailThemeValue}
                    />
                  </Box>
                </LegacyCard>
              ) : null}
            </Layout.Section>
          </div>
          <Layout.Section>
            <LegacyCard sectioned>
              {useStyling ? (
                <EmailPreview
                  context={context}
                  description={activeEmailPreview.description ?? ""}
                  title={activeEmailPreview.title}
                  onPreview={onPreview}
                  previewHTML={previewHTML}
                  showEmailPreview={!!context.subscription}
                  pagination={{
                    onNext: () => paginationHandler(1),
                    onPrevious: () => paginationHandler(-1),
                  }}
                />
              ) : (
                <EmptyStateWrapper>
                  <EmptyState
                    image=""
                    heading="Default templates are disabled"
                    action={{
                      content: "Enable templates",
                      onAction: () => toggleUseStylingRadio(),
                    }}
                  >
                    {" "}
                    <p>Enable them to customize and preview your customer notification emails.</p>
                  </EmptyState>
                </EmptyStateWrapper>
              )}
            </LegacyCard>
          </Layout.Section>
        </Layout>
      </Page>
      <EmailNotificationPreviewModal />
    </Box>
  );
};
